import { createTheme, responsiveFontSizes } from "@mui/material";

// Use these sites to help create a theme:
// https://mui.com/customization/theming/#main-content
// https://bareynol.github.io/mui-theme-creator/
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=33691E&secondary.color=0D47A1
// https://mui.com/material-ui/customization/default-theme/#main-content

const baseHeadingTypography = {
  fontFamily: '"Poppins", sans-serif',
  fontOpticalSizing: "auto",
  fontWeight: 500,
  fontStyle: "normal",
};

export const theme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xxs: 0,
        xs: 350,
        sm: 600,
        md: 950,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      type: "light",
      background: {
        default: "#fff",
      },
      primary: {
        main: "#33691e",
        contrastText: "#f5f5f5",
      },
      secondary: {
        main: "#3e3e3e",
        light: "#545454",
        contrastText: "#e3e3e3",
      },
      text: {
        primary: "#272727",
        secondary: "#646464",
      },
    },
    typography: {
      baseHeading: baseHeadingTypography,
      h1: {
        fontSize: {
          xxs: "2.5rem",
          md: "3.5rem",
        },
        lineHeight: 1.2,
        marginBottom: "1rem",
        fontWeight: 600,
        ...baseHeadingTypography,
      },
      h2: {
        fontSize: {
          xxs: "1.75rem",
          md: "2.25rem",
        },
        lineHeight: 1.3,
        letterSpacing: 0.25,
        marginBottom: "0.875rem",
        ...baseHeadingTypography,
      },
      h3: {
        fontSize: {
          xxs: "1.5rem",
          md: "1.75rem",
        },
        lineHeight: 1.4,
        letterSpacing: 0.2,
        ...baseHeadingTypography,
      },
      h4: {
        fontSize: {
          xxs: "1.2rem",
          md: "1.4rem",
        },
        lineHeight: 1.4,
        letterSpacing: 0.15,
        ...baseHeadingTypography,
      },
      h5: {
        fontSize: {
          xxs: "1rem",
          md: "1.1rem",
        },
        lineHeight: 1.5,
        letterSpacing: 0.1,
        ...baseHeadingTypography,
      },
      body1: {
        fontSize: "1rem",
        lineHeight: 1.6,
        letterSpacing: 0.15,
      },
      body2: {
        fontSize: "0.875rem",
        lineHeight: 1.6,
        letterSpacing: 0.15,
      },
      caption: {
        fontSize: "0.75rem",
        lineHeight: 1.5,
        letterSpacing: 0.4,
      },
    },
    zIndex: { appBar: 1300 },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.text.primary,
          }),
        },
      },
      MuiLink: {
        styleOverrides: {
          root: ({ theme, ownerState }) =>
            ownerState["data-basic"]
              ? {}
              : {
                  padding: "2px 0",
                  color: theme.palette.primary.dark,
                  cursor: "pointer",
                  textDecoration: "none",
                  "&:hover": {
                    borderBottom: "1px solid currentColor",
                  },
                },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: () => ({ textTransform: "initial", ...theme.typography.h5 }),
          sizeLarge: () => ({
            paddingTop: "9px",
            paddingBottom: "9px",
            fontSize: "1.3rem",
          }),
          sizeMedium: () => ({
            paddingTop: "8px",
            paddingBottom: "8px",
            fontSize: "1rem",
          }),
          sizeSmall: () => ({
            paddingTop: "5px",
            paddingBottom: "5px",
            fontSize: "0.85rem",
          }),
        },
        variants: [
          {
            props: { variant: "contained-inverted" },
            style: ({ theme, ownerState }) => ({
              color: theme.palette[ownerState.color].main,
              background: theme.palette[ownerState.color].contrastText,
              "&:hover": {
                background: theme.palette[ownerState.color].contrastText,
                opacity: 0.9,
              },
            }),
          },
          {
            props: { variant: "outlined-inverted" },
            style: ({ theme, ownerState }) => ({
              color: theme.palette[ownerState.color].contrastText,
              border: `1px solid ${
                theme.palette[ownerState.color].contrastText
              }`,
            }),
          },
        ],
      },
    },
  })
);
