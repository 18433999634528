import {
  AbButton,
  ILink,
  useIsMobile,
  useIsTablet,
} from "@advocatebase/web-components";
import {
  Box,
  Card,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { ASSETS_BASE_URL, defaultHeaderImage } from "../consts";
import FeaturesSection from "./FeaturesPage/FeaturesSection";
import PricingSection from "./PricingPage/PricingSection";

interface HomePageProps {
  primaryLink: ILink;
  secondaryLink: ILink;
}

const HomePage = ({ primaryLink }: HomePageProps) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isSmallHeight = useMediaQuery("(max-height:500px)");

  return (
    <Box>
      <Helmet>
        <title>AdvocateBase</title>
        <meta
          name="description"
          content="Build the foundation for your movement with AdvocateBase, a volunteer management app for non-profit organisations."
        />
      </Helmet>
      <Box
        aria-label="header"
        component="header"
        sx={{
          width: "100%",
          position: "relative",
          bgcolor: "#2B4C2B",
          py: 8,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            position: "relative",
            zIndex: 1,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: { xs: 4, md: 0 },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              pr: { xs: 0, md: 6 },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                lineHeight: 1.2,
                mb: 3,
                color: "white",
              }}
            >
              Volunteer Management App for Non-Profits
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "1.25rem",
                fontWeight: 400,
                mb: 4,
                color: "white",
                opacity: 0.9,
              }}
            >
              Build the foundation for your movement with powerful tools
              designed for non-profit organisations.
            </Typography>
            <AbButton
              href={primaryLink.href}
              variant="contained"
              size="large"
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                py: 1.5,
                px: 4,
              }}
              color="warning"
            >
              {primaryLink.label}
            </AbButton>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Card
              elevation={8}
              sx={{
                width: "100%",
                borderRadius: 4,
                height: 0,
                paddingTop: "66.67%",
                position: "relative",
                overflow: "hidden",
                background: "transparent",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url("${defaultHeaderImage}")`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </Card>
          </Box>
        </Container>
      </Box>
      <Box sx={{ background: (theme) => theme.palette.grey[300], py: 4 }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: isMobile ? 2 : 4,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{ alignItems: "center" }}
            spacing={{ xs: 2, sm: 0 }}
          >
            <Box
              sx={{
                width: isMobile ? "100%" : "75%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h2">Our Mission</Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ textAlign: "center" }}
              >
                Our mission is to amplify the influence of your non-profit by
                strengthening and streamlining your volunteer management for
                maximum impact.
              </Typography>
            </Box>
            <Box
              sx={{
                width: isMobile ? "100%" : isTablet ? "50%" : "500px",
                display: "flex",
                alignItems: "center",
                overflowX: "clip",
              }}
            >
              <Box
                component="img"
                src={`${ASSETS_BASE_URL}/advocatebase/screenshots/mobile_teams_map_3.jpg`}
                alt="AdvocateBase"
                sx={{
                  maxWidth: "100%",
                  padding: 0,
                  margin: 0,
                  ...(isTablet && !isMobile && { scale: "1.2" }),
                }}
              />
            </Box>
          </Stack>
        </Container>
      </Box>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 4,
        }}
      >
        <Typography variant="h2">Features</Typography>
        <FeaturesSection buttonLink={primaryLink} />
      </Container>
      <Box sx={{ background: (theme) => theme.palette.grey[300], py: 4 }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: 0,
          }}
        >
          <Typography variant="h2">Pricing</Typography>
          <PricingSection buttonLink={primaryLink} />
        </Container>
      </Box>
    </Box>
  );
};

export default HomePage;
